import { storage } from "firebase";
import React, { useEffect, useState } from "react";
import { auth, databaseGetUser, db, firestoreGetDipartimenti } from "../firebase/firebase";

import * as Sentry from "@sentry/react";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentRole, setCurrentRole] = useState(null);
  const [userData, setUserData] = useState(null);
  const [dipartimenti, setDipartimenti] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);

  function CaricaDipartimenti() {
    firestoreGetDipartimenti().then((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDipartimenti(data);
    }).catch((err) => {
      console.log("loading departments ERROR", err)
    });
  }

  useEffect(() => {
    if (!currentUser) {
      setLoginLoading(true)
      auth.onAuthStateChanged((user) => {
        if (user) {
          setCurrentUser(user);
          setLoginLoading(true)
        } else {
          setLoginLoading(false)
          setCurrentUser(null);
          setUserData(null);
        }
      });
    } else {
      setLoginLoading(false)
      if (!userData) {
        //check if is unifg.it
        if (["cedisma.it", "unicatt.it", "inqubit.in", "unifg.it"].indexOf(currentUser.email.split("@")[1]) === -1) {
          setUserData({ email: "logout" }); //login will read this and logout
        } else {
          // is unifg.it
          
          Sentry.setUser({ email: currentUser.email });
          
          databaseGetUser(currentUser.email, onGetUser);
        }
      }
    }
  }, [currentUser]);

  function onGetUser(dataSnapshot) {
    // This should return just one user
    const utenteSnapshot = dataSnapshot.val();
    let utente = null;
    if (utenteSnapshot) {
      for (let key in utenteSnapshot) { utente = utenteSnapshot[key] }
    }
    
    if (utente) {
      if (parseInt(currentUser.email.split("@")[0].split(".")[1])) {
        /*
        DISABLE ACCESS FOR STUDENTS THAT ALREADY LOGGED IN
        
        setUserData(utenti[0]);
        setCurrentRole("Studente");
        */
        setUserData({ email: "logout" });
      } else {
        switch (parseInt(utente.tipo)) {
          case 1:
            setCurrentRole("Docente");
            setUserData(utente);
            // CaricaDipartimenti();
            break;
          case 2:
            setCurrentRole("Amministratore");
            setUserData(utente);
            // CaricaDipartimenti();
            break;
          case 3:
            setCurrentRole("SuperAdmin");
            setUserData(utente);
            // CaricaDipartimenti();
            break;
          default:
            setUserData({ email: "logout" });
            break;
        }
      }
    } else {
      if (parseInt(currentUser.email.split("@")[0].split(".")[1])) {
        /*
        DISABLE ACCESS FOR STUDENTS THAT NEVER LOGGED IN
        
        setCurrentRole("Studente");
        setUserData({ email: "nodata" });
      } else {
        */
        setUserData({ email: "logout" }); //login will read this and logout
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentRole,
        userData,
        dipartimenti,
        loginLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
