import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { auth, provider } from "../../firebase/firebase";
import { AuthContext } from "../../auth/AuthProvider";

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCardHeader,
  CCol,
  CContainer,
  CImg,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from "@coreui/react";
import { Version } from "../../assets/utils/EccomiVersion";

const UserLogin = ({ history }) => {
  const { userData, loginLoading } = useContext(AuthContext);
  const [userLoggedIn, setLoggedIn] = useState(false);
  const [modal, setModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [bodyModal, setBodyModal] = useState("");

  function loginWithGoogle() {
    auth.signInWithRedirect(provider);
  }

  useEffect(() => {
    if (userData && !userLoggedIn) {
      if (userData.email === "logout") {
        auth.signOut();
        setTitleModal("Accesso negato");
        setBodyModal(
          "Attenzione! L'accesso è autorizzato ai Docenti e T.A. utilizzando le email istituzionali @cedisma.it / @unicatt.it"
        );
        setModal(true);
      } else {
        setLoggedIn(true);
        history.push("/profilo");
      }
    } else {
      setLoggedIn(false);
    }
  }, [userData]);

  /*
                    <p>Stiamo effettuando degli aggiornamenti.</p>
                    <p>Ritorneremo online entro poche ore.</p>
  */

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard align="center">
                <CCardHeader>
                  <img
                    src="eccomi-logo-outline.svg"
                    alt="Eccomi Logo"
                    style={{
                      height: 50,
                    }}
                  />
                  <img
                    src="logo-unicatt.png"
                    alt="Università Cattolica del Sacro Cuore"
                    style={{
                      height: 50,
                    }}
                  />
                </CCardHeader>
                <CCard>
                  <CCardBody>
                    <p>
                      <b>
                        L'accesso alle prenotazioni in presenza è consentito
                        solo attraverso le APP Android e IOS.
                      </b>
                    </p>
                    <p>Versione Android</p>
                    <CLink href="https://play.google.com/store/apps/details?id=com.inqubit.eccomi_tfa_unicatt">
                      <CImg
                        src="google-play-icon.png"
                        alt="Android APP"
                        style={{
                          height: 50,
                        }}
                      />
                    </CLink>
                    <p>
                      <br />
                      Versione IOS
                    </p>
                    <CLink href="https://apps.apple.com/us/app/eccomi-tfa-unicatt/id6449050052">
                      <CImg
                        src="apple-store-icon.png"
                        alt="IOS APP"
                        style={{
                          height: 50,
                        }}
                      />
                    </CLink>
                  </CCardBody>
                </CCard>
                <CCardBody>
                  {
                    loginLoading ? 
                      <CSpinner
                        color="primary"
                        className="ml-2"
                      /> : 
                      <img
                        src="btn_google_signin_custom.png"
                        alt="Google Login"
                        style={{
                          height: 50,
                        }}
                        onClick={() => {
                          loginWithGoogle();
                        }}
                      />
                  }
                </CCardBody>
                <CCardFooter className="d-flex align-items-center justify-content-center">
                  <span>
                    Ver {
                    Version
                  }
                  </span>
                </CCardFooter>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>

        <CModal show={modal} onClose={setModal}>
          <CModalHeader closeButton>
            <CModalTitle>{titleModal}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <p>{bodyModal}</p>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" onClick={() => setModal(false)}>
              OK
            </CButton>
          </CModalFooter>
        </CModal>
      </CContainer>
    </div>
  );
};

export default withRouter(UserLogin);
