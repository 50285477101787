import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";

// sidebar nav config
import navigationStudente from "./_navStudente";
import navigationDocente from "./_navDocente";
import navigationAmministratore from "./_navAmministratore";
import navigationSuperAdmin from "./_navSuperAdmin";

import { AuthContext } from "../auth/AuthProvider";

const TheSidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow);
  const [loading, setLoading] = useState(true);
  const [currentNavigation, setCurrentNavigation] = useState(false);
  const { currentRole, userData } = useContext(AuthContext);

  useEffect(() => {
    let adminRoutes = [...navigationAmministratore];

    if (currentRole === "Amministratore") {
      if (userData?.tfa === true) {
        adminRoutes.push(
          {
            _tag: "CSidebarNavTitle",
            _children: ["TFA"],
          },
          {
            _tag: "CSidebarNavItem",
            name: "TFA Docenti",
            to: "/tfa_docenti",
            icon: "cil-file",
          },
          {
            _tag: "CSidebarNavItem",
            name: "TFA Materie",
            to: "/materie_tfa",
            icon: "cil-file",
          },
          {
            _tag: "CSidebarNavItem",
            name: "TFA Corsi",
            to: "/corsi_tfa",
            icon: "cil-file",
          },
          {
            _tag: "CSidebarNavItem",
            name: "TFA Aule",
            to: "/aule_tfa",
            icon: "cil-file",
          }
        );

        if (userData?.tfaLezioni === true) {
          adminRoutes.push({
            _tag: "CSidebarNavItem",
            name: "TFA Lezioni",
            to: "/tfa_lezioni",
            icon: "cil-file",
          });
        }

        adminRoutes.push(
          {
            _tag: "CSidebarNavItem",
            name: "TFA Presenze",
            to: "/presenze_tfa",
            icon: "cil-file",
          },
          {
            _tag: "CSidebarNavItem",
            name: "TFA Ore di Assenza",
            to: "/tfa_ore_di_assenza",
            icon: "cil-file",
          }
        );
      } else if (userData?.tfaLezioni === true) {
        // TFA Amministratore Lezioni
        adminRoutes.push(
          {
            _tag: "CSidebarNavTitle",
            _children: ["TFA"],
          },
          {
            _tag: "CSidebarNavItem",
            name: "TFA Lezioni",
            to: "/tfa_lezioni",
            icon: "cil-file",
          }
        );
      } else if (userData?.tfaPresenze === true) {
        adminRoutes.push(
          {
            _tag: "CSidebarNavTitle",
            _children: ["TFA"],
          },
          {
            _tag: "CSidebarNavItem",
            name: "TFA Presenze",
            to: "/presenze_tfa",
            icon: "cil-file",
          }
        );
      }
    }

    if (currentRole) {
      switch (currentRole) {
        case "Studente":
          setCurrentNavigation(navigationStudente);
          break;
        case "Docente":
          setCurrentNavigation(navigationDocente);
          break;
        case "Amministratore":
          setCurrentNavigation(adminRoutes);
          break;
        case "SuperAdmin":
          setCurrentNavigation(navigationSuperAdmin);
          break;
        default:
          setCurrentNavigation(null);
          break;
      }
      setLoading(false);
    }
  }, [userData]);

  if (loading) {
    return <p>Attendere prego...</p>;
  } else {
    return (
      <CSidebar
        show={show}
        unfoldable
        onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
      >
        <CSidebarBrand className="d-md-down-none" to="/">
          <img
            src="eccomi-logo-outline-negative.svg"
            alt="Eccomi Logo"
            style={{
              height: 50,
            }}
          />
        </CSidebarBrand>

        <CSidebarNav>
          <CCreateElement
            items={currentNavigation}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        </CSidebarNav>
      </CSidebar>
    );
  }
};

export default React.memo(TheSidebar);
