import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";
import "firebase/functions";
import moment from "moment";
import { v4 } from "uuid";
// import firebaseConfig from '../firebaseConfig';

export const provider = new firebase.auth.GoogleAuthProvider();

//  Eccomi Firebase Config

const firebaseConfig = {
  apiKey: "AIzaSyCFJpdGlb8e4v3x-ocBUKs60ItOkr02Jjk",
  authDomain: "eccomi-unicatt.firebaseapp.com",
  databaseURL: "https://eccomi-unicatt-default-rtdb.europe-west1.firebasedatabase.app",
  //databaseURL: "http://127.0.0.1:9000/?ns=eccomi-unicatt-default-rtdb",
  projectId: "eccomi-unicatt",
  storageBucket: "eccomi-unicatt.appspot.com",
  messagingSenderId: "307429583234",
  appId: "1:307429583234:web:4b018f5c37b36a74ac879b",
  measurementId: "G-WDBB362PHB"
};

//  Eccomi UI dev Firebase Config

// const firebaseConfig = {
//   apiKey: "AIzaSyDMA8_de3OYkht8HjUMDc-2GIW-gL1zkcc",
//   authDomain: "eccomi-io-dev.firebaseapp.com",
//   databaseURL: "https://eccomi-io-dev-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "eccomi-io-dev",
//   storageBucket: "eccomi-io-dev.appspot.com",
//   messagingSenderId: "370568604983",
//   appId: "1:370568604983:web:2cc8247939e558e3defeb2",
//   measurementId: "G-5VFFDFRZS5"
// };

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const database = firebase.database();
export const functions = firebase.functions();

if (window.location.hostname === "localhost") {
  // auth.useEmulator("http://localhost:9099");
  // db.useEmulator("localhost", '8080');
  // database.useEmulator("localhost", '9000');
  // functions.useEmulator('localhost', '5001');
} 

export function databaseGetTFAUsers() {
  return database.ref("/utenti").orderByChild('tfa').equalTo(true).once("value");
}

export function databaseGetUsers(onGetUsersCallback) {
  const ref = database.ref("/utenti");
  ref.on("value", onGetUsersCallback);
}

export function databaseAddDocenteUser(email, codiceFiscale, matricola, tipo) {
  return new Promise((resolve, reject) => {
    firebase.auth().createUserWithEmailAndPassword(email, "!DaModificare#2023!")
      .then((userCredential) => {                
        database.ref("utenti/" + userCredential.user.uid).update({
          email: email,
          codiceFiscale: codiceFiscale,
          matricola: matricola,
          tipo: tipo
        }).then(() => {
          resolve("OK")
        }).catch((err) => {
          reject(err)
        });
      }).catch((createUserError) => {
        reject(createUserError)
      })
  }); 
}

export function databaseAddStudentUser(email, codiceFiscale, matricola, tipo, tfa, corsiTfa) {
  return new Promise((resolve, reject) => {
    firebase.auth().createUserWithEmailAndPassword(email, "!DaModificare#2023!")
      .then((userCredential) => {        
        database.ref("utenti/" + userCredential.user.uid).update({
          email: email,
          codiceFiscale: codiceFiscale,
          matricola: matricola,
          tipo: tipo,
          tfa: tfa,
          corsiTfa: corsiTfa
        }).then(() => {
          resolve("OK")
        }).catch((err) => {
          reject(err)
        });
      }).catch((createUserError) => {
        reject(createUserError)
      })
  });  
}

export function databaseAddUser(email, codiceFiscale, matricola, tipo, tfa, tfaPresenze, gestireAuleSpeciali, corsiTfa, gestirePrenotazioni) {
  return new Promise((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(email, "!DaModificare#2023!")
        .then((userCredential) => {
          database.ref("utenti/" + userCredential.user.uid).update({
            email: email,
            codiceFiscale: codiceFiscale,
            matricola: matricola,
            tipo: tipo,
            tfa: tfa,
            tfaPresenze: tfaPresenze,
            gestireAuleSpeciali: gestireAuleSpeciali,
            corsiTfa: corsiTfa
        }).then(() => {
          resolve("OK")
        }).catch((err) => {
          reject(err)
        });
      }).catch((createUserError) => {
        reject(createUserError)
      })
  });  
}

export function databaseAddUtentiTfaUser(userData, academicYear) {
  return new Promise((resolve, reject) => {
    firebase.auth().createUserWithEmailAndPassword(userData.email, "!DaModificare#2023!")
      .then((userCredential) => {
        let payload = {
          email: userData.email,
          nome: userData.nome,
          cognome: userData.cognome,
          tipo: userData.tipo,
          codiceFiscale: userData.codiceFiscale,
          impostazioniPrenotazioni: { 
            corsoDiLaurea: userData.corso,
            academicYear: academicYear
          },
          matricola: userData.matricola,
          tfa: userData.tfa,
        }

        console.log("databaseAddUtentiTfaUser", payload)
        database.ref("utenti/" + userCredential.user.uid).update(payload).then(() => {
        resolve("OK")
      }).catch((err) => {
        reject(err)
      });
    }).catch((createUserError) => {
      reject(createUserError)
    })
  });  
}

// export function databaseAddUtentiTfaUser(email, codiceFiscale, matricola, tipo, tfa, nome, cognome, corso) {
//   return new Promise((resolve, reject) => {
//     firebase.auth().createUserWithEmailAndPassword(email, "!DaModificare#2023!")
//       .then((userCredential) => {
//         database.ref("utenti/" + userCredential.user.uid).update({
//           email: email,
//           nome: nome,
//           cognome: cognome,
//           tipo: tipo,
//           codiceFiscale: codiceFiscale,
//           impostazioniPrenotazioni: { 
//             corsoDiLaurea: corso
//           },
//           matricola: matricola,
//           tfa: tfa,
//       }).then(() => {
//         resolve("OK")
//       }).catch((err) => {
//         reject(err)
//       });
//     }).catch((createUserError) => {
//       reject(createUserError)
//     })
//   });  
// }

export async function databaseImportTFAUsers(users) {
  let statuses = users;
  for (let i=0; i <= users.length-1; i++) {
    let user = users[i]

    try {
      let userCredential = await firebase.auth().createUserWithEmailAndPassword(user.email, "!DaModificare#2023!")

      let payload = {
        email: user.email,
        nome: user.nome,
        cognome: user.cognome,
        codiceFiscale: user['codice fiscale'],
        tipo: "0",
        impostazioniPrenotazioni: { 
          corsoDiLaurea: user.corso,
          anno: "1",
          dipartimento: "00001", //ATENEO
        },    
        tfa: true,
      }

      await database.ref("utenti/" + userCredential.user.uid).update(payload)

      statuses[i].status = 'Success';
    } catch (err) {
      statuses[i].status = `${err}`;
    }
  }
  
  return statuses;
}

export function databaseUpdateUser(id, valuesToUpdate) {
  database.ref("utenti/" + id).update(valuesToUpdate);
}

export function databaseDeleteUser(id, onDeleteUserCallback) {
  database.ref("utenti/" + id).remove(onDeleteUserCallback);
}

export function databaseGetUser(email, onGetUserCallback) {
  database.ref("utenti").orderByChild('email').equalTo(email).once("value", onGetUserCallback);
}

export function databaseGetStudenti(onGetStudentiCallback) {
  database.ref("utenti").orderByChild('tipo').equalTo("0").once("value", onGetStudentiCallback);
}

export function firestoreGetUsers(field, operator, value) {
  if (field && operator && value) {
    return db.collection("utenti").where(field, operator, value).get();
  } else {
    return db.collection("utenti").get();
  }
}

export function firestoreGetStudenti() {
  return db.collection("utenti").where("tipo", "==", "0").get();
}

export function firestoreGetSettings() {
  return db.collection("eccomi_native_app_settings").get();
}

// TODO: REMOVE
export function firestoreGetLog() {
  return db.collection("eccomi_native_app_log").limit(100).get();
}

export function firestoreGetDipartimenti() {
  return db.collection("dipartimenti").get();
}

export function firestoreGetCorsi() {
  return db.collection("corsi").get();
}

export function firestoreGetCorsiTfa(academicYear) {
  if (academicYear) {
    return db.collection("corsi_tfa")
      .where("academicYear", "==", academicYear)
      .get();
  } else {
    return db.collection("corsi_tfa").get();
  }
}

export function firestoreGetCorsiTfaByCodice(codice) {
  return db.collection("corsi_tfa")
  .where("codice", "==", codice)
  .get();
}

export function firestoreAmministratoreGetCorsiTfa(corsi) {
  console.log("firestoreAmministratoreGetCorsiTfa CORSI", corsi)
  if (corsi[0].toLowerCase() == "tutti") {
    return db.collection("corsi_tfa")
      .get();
  } else {
    return db.collection("corsi_tfa")
      .where("descrizione", "in", corsi)
      .get();
  }
}

export function firestoreGetLezioniTfa(date, academicYear) {
  console.log("firestoreGetLezioniTfa", date, academicYear)
  if (date) {
    if (academicYear) {
      return db.collection("lezioni_tfa")
        .where("academicYear", "==", academicYear)
        .where("isRecoveryLesson", "==", false)
        .get();
    } else {
      return db.collection("lezioni_tfa")
        .where("isRecoveryLesson", "==", false)
        .get();
    }
  } else {
    if (academicYear) {
      return db.collection("lezioni_tfa")
        .where("academicYear", "==", academicYear)
        .where("isRecoveryLesson", "==", false)
        .get();
    } else {
      return db.collection("lezioni_tfa")
        .where("isRecoveryLesson", "==", false)
        .get();
    }
  }
}

export function firestoreAmministratoreGetLezioniTfa(corsi, activeAcademicYear) {
  return db.collection("lezioni_tfa")
  .where("corso.descrizione", "in", corsi)
  .get();
}

export function firestoreGetOreDiAssenzaLezioni(corso, academicYear) {
  return db.collection("lezioni_tfa")
  .where("corso.codice", "==", corso)
  .where("academicYear", "==", academicYear)
  .orderBy("orario.orarioInizioEpoch", "desc")
  .get();
}

export function firestoreGetTFALessonsByCourseAndDate(course, date) {
  console.log("firestoreGetTFALessonsByCourseAndDate", course , date)
  return db.collection("lezioni_tfa")
  .where("corso.codice", "==", course)
  .where("orario.data", "==", date)
  .get();
}

export function firestoreGetTFALessonsByCourse(course) {
  return db.collection("lezioni_tfa")
  .where("corso.codice", "==", course)
  .orderBy("orario.orarioInizioEpoch")
  .get();
}

export function firestoreGetLezioniTfaByMateria(materia) {
  return db.collection("lezioni_tfa")
  .where("materia", "==", materia)
  .get();
}

export function firestoreGetdocentiTfa() {
  return db.collection("docenti_tfa").get();
}

export function firestoreGetMaterieTfa() {
  return db.collection("materie_tfa").get();
}

export function firestoreGetAuleTfa() {
  return db.collection("aule_tfa").get();
}

export function firestoreGetAule(aulaDiServizio) {
  if (aulaDiServizio)
    return db.collection("aule").where("aulaDiServizio", "==", true).get();
  else
    return db.collection("aule").get();
}

export function firestoreGetPrenotazioni(codiceDipartimento, data) {
  return db
    .collection("prenotazioni")
    .where("impegno.unitaOrganizzativa.codice", "==", codiceDipartimento)
    .where("impegno.orario.data", "==", data)
    .get();
}

// TFA
export function firestoreGetTfaOreAssenzaPresenze(academicYear, student_surname, subject, course) {
  if (student_surname && subject) {
    return db
      .collection("presenze_tfa")
        .where("academicYear", "==", academicYear)
        .where("materia", "==", subject)
        .where("isRecoveryLesson", "==", false)
        .orderBy('studente.cognome').startAt(student_surname).endAt(student_surname + "\uf8ff")
        .get();
  } else if (student_surname && course) {
    return db
      .collection("presenze_tfa")
        .where("corso.codice", "==", course)
        .where("isRecoveryLesson", "==", false)
        .orderBy('studente.cognome').startAt(student_surname).endAt(student_surname + "\uf8ff")
        .get();
  } else if (subject) {
    return db
      .collection("presenze_tfa")
        .where("materia", "==", subject)
        .where("isRecoveryLesson", "==", false)
        .get();
  } else if (course) {
    return db
      .collection("presenze_tfa")
        .where("corso.codice", "==", course)
        .where("isRecoveryLesson", "==", false)
        .get();
  } else {
    return db
      .collection("presenze_tfa")
        .where("academicYear", "==", academicYear)
        .where("isRecoveryLesson", "!=", true)
        .get();
  }

}

export function firestoreGetPresenzeTfa(cercaCorsoValue, data, annoAccademico) {
  // Keep AnnoAccademico search optional for now, to allow old data to be searched.
  console.log(cercaCorsoValue, data, annoAccademico)
  if (!annoAccademico) {
    return db
      .collection("presenze_tfa")
      .where("corso.codice", "==", cercaCorsoValue)
      .where("orario.data", "==", data)
      .get();
  } else {
    return db
      .collection("presenze_tfa")
      .where("corso.codice", "==", cercaCorsoValue)
      .where("orario.data", "==", data)
      // .where("academicYear", "==", annoAccademico)
      .get();
  }
}

export function firestoreGetPresenzeTFA(cercaCorsoValue, annoAccademico) {
  return db
    .collection("presenze_tfa")
    .where("corso.codice", "==", cercaCorsoValue)      
    .get();
}

export function firestoreGetPrenotazioniTracciamentoByCodice(codice, data) {
  return db
    .collection("prenotazioni")
    .where("impegno.codice", "==", codice)
    .where("impegno.orario.data", "==", data)
    .get();
}

export function firestoreGetPrenotazioniTracciamento(email, data) {
  return db
    .collection("prenotazioni")
    .where("studente.email", "==", email)
    .where("impegno.orario.data", "==", data)
    .get();
}

export function firestoreGetPrenotazioniAuleSpeciali(codiceDipartimento, data) {
  return db
    .collection("prenotazioni")
    .where("impegno.unitaOrganizzativa.codice", "==", codiceDipartimento)
    .where("impegno.orario.data", "==", data)
    .where("aulaDiServizio.codiceAula", "!=", "")
    .get();
}
// TODO: REMOVE
export function firestoreGetPrenotazioniAulaSpeciale(codiceDipartimento, data, codiceAula) {
  return db
    .collection("prenotazioni")
    .where("impegno.unitaOrganizzativa.codice", "==", codiceDipartimento)
    .where("impegno.orario.data", "==", data)
    .where("aulaDiServizio.codiceAula", "==", codiceAula)
    .get();
}

export function firestoreGetAuleSpecialiDisponibili(codiceDipartimento, data) {
  const giornoData = moment(data, 'YYYY-MM-DD').format("dddd")
  
  const giornoMap = {
    Sunday: 'Domenica',
    Monday: 'Lunedì',
    Tuesday: 'Martedì',
    Wednesday: 'Mercoledì',
    Thursday: 'Giovedì',
    Friday: 'Venerdì',
    Saturday: 'Sabato',
  }

  const giornoFinale = giornoMap[giornoData]

  return db
    .collection("aule")
    .where("aulaDiServizio", "==", true)
    .where("codiceDipartimento", "==", codiceDipartimento)
    .where("giorniDisponibili", "array-contains", giornoFinale)
    .get();
}

// export const firebaseGeneratePDFs = async (data) => {
//   var generatePDFsCallable = functions.httpsCallable('generatePDFs');
//   return await generatePDFsCallable(data);
// }

export const firebaseGetZippedPDFsAsync = async (data) => {
  var getZippedPDFsAsyncCallable = functions.httpsCallable('getZippedPDFsAsync');
  return await getZippedPDFsAsyncCallable(data);
}

// Test function.
// export const firebaseSendEmail = async (data) => {
//   var sendEmailCallable = functions.httpsCallable('sendEmail');
//   return await sendEmailCallable(data);
// }

export const firebaseEmailCertificatePDFs = async (data) => {
  var emailCertificatePDFsCallable = functions.httpsCallable('emailCertificatePDFs');
  return await emailCertificatePDFsCallable(data);
}

export const firestoreTFAUpdatePunchOutOnPresence = (presenzaId, punchIns) => {
  return db.collection('presenze_tfa').doc(presenzaId).update({
    punchIns: punchIns
  })
}

export const firestoreTFAGetAllPresencesForStudent = (student_email, course, date) => {
  if (student_email && course && date) {
    return db
      .collection("presenze_tfa")
      .where("studente.email", "==", student_email)
      .where("corso.codice", "==", course)
      .where("orario.data", "==", date)
      .get();
  } else if (student_email && course) {
    return db
      .collection("presenze_tfa")
      .where("studente.email", "==", student_email)
      .where("corso.codice", "==", course)
      .get();
  } else if (student_email && date) {
    return db
      .collection("presenze_tfa")
      .where("studente.email", "==", student_email)
      .where("orario.data", "==", date)
      .get();
  } else if (student_email) {
    return db
      .collection("presenze_tfa")
      .where("studente.email", "==", student_email)
      .get();
  }
}

export const firestoreTFAGetPresencesByLessonData = (lezioneData) => {
  return db
      .collection("presenze_tfa")
      .where("orario.data", "==", lezioneData.orario.data)
      .where("orario.orarioInizio", "==", lezioneData.orario.orarioInizio)
      .where("orario.orarioFine", "==", lezioneData.orario.orarioFine)
      .where("materia", "==", lezioneData.materia)
      .where("corso.codice", "==", lezioneData.corso.codice)
      .orderBy("orario.orarioInizioEpoch")
      .get();
}

export const firestoreTFAGetPresencesByStudentEmail = (student_email) => {
  return db
      .collection("presenze_tfa")
      .where("studente.email", "==", student_email)
      .orderBy("orario.orarioInizioEpoch")
      .get();
}

export const firestoreTFAGetPresencesByStudentEmailAndDate = (student_email, date) => {
  return db
      .collection("presenze_tfa")
      .where("studente.email", "==", student_email)
      .where("orario.data", "==", date)
      .orderBy("orario.orarioInizioEpoch")
      .get();
}

export const firestoreTFAGetPresencesByStudentSurname = (student_surname, academicYear) => {
  if (academicYear) {
    return db
      .collection("presenze_tfa")
      .orderBy("studente.cognome")
      .startAt(student_surname)
      .endAt(student_surname + '\uf8ff')
      .get();
  } else {
    return db
      .collection("presenze_tfa")
      .orderBy("studente.cognome")
      .startAt(student_surname)
      .endAt(student_surname + '\uf8ff')
      .get();
  }
}

export const firestoreTFAGetPresencesByStudentSurnameAndDate = (student_surname, date, academicYear) => {
  console.log('firestoreTFAGetPresencesByStudentSurnameAndDate', student_surname, date, academicYear)
  if (!academicYear) {
    return db
        .collection("presenze_tfa")
        .where("orario.data", "==", date)
        .orderBy("studente.cognome")
        .startAt(student_surname)
        .endAt(student_surname + '\uf8ff')
        .get();
  } else {
    return db
        .collection("presenze_tfa")
        .where("orario.data", "==", date)
        // .where("academicYear", "==", academicYear)
        .orderBy("studente.cognome")
        .startAt(student_surname)
        .endAt(student_surname + '\uf8ff')
        .get();
  }
}

export const firestoreTFAGetPresencesByStudentEmailAndLesson = (student_email, lesson) => {
  return db
      .collection("presenze_tfa")
      .where("orario.data", "==", lesson.orario.data)
      .where("orario.orarioInizio", "==", lesson.orario.orarioInizio)
      .where("orario.orarioFine", "==", lesson.orario.orarioFine)
      .where("materia", "==", lesson.materia)
      .where("corso.codice", "==", lesson.corso.codice)
      .where("studente.email", "==", student_email)
      .get();
}

export const firebaseUpdatePresencePunchins = (presenceId, punchIns) => {
  console.log('firebaseUpdatePresencePunchins', presenceId, punchIns)
  // return db.
  //   collection("presenze_tfa")
  //   .doc(presenceId)
  //   .update(punchIns)
}

export const firebaseAddPresence = (presenceData, callback) => {
  console.log('firebaseAddPresence', presenceData)
   db.
    collection("presenze_tfa")
      .add(presenceData)
      .then(() => {
        console.log("firebaseAddPresence NEW")
        callback()
      })
      .catch((err) => {
        console.log("firebaseAddPresence ERR", err)
      })
}

// Academic year

export const firestoreTFAGetAcademicYears = () => {
  return db
    .collection("anni_accademici_tfa")
    .orderBy("nome")
    .get();
}

export const firestoreTFAAddAcademicYear = (academicYearData) => {
  return db
    .collection("anni_accademici_tfa")
    .add(academicYearData)
}

export const firestoreTFAModifyAcademicYear = (id, academicYearData) => {
  return db
    .collection("anni_accademici_tfa")
    .doc(id)
    .update(academicYearData)
}

export const firestoreTFADeleteAcademicYear = (id) => {
   return  db.collection("anni_accademici_tfa")
    .doc(id)
    .delete()
}

export const firestoreTFAGetActiveAcademicYear = () => {
  return  db.collection("anni_accademici_tfa")
   .where('attivo', "==", true)
   .get()
}

export const firestoreTFAGetAcademicYearByName = (name) => {
  return  db.collection("anni_accademici_tfa")
   .where('nome', "==", name)
   .get()
}

export const firestoreTFAGetLinkedCourseToAcademicYear = (academicYearName) => {
  return db.collection("corsi_tfa")
   .where('academicYear', "==", academicYearName)
   .limit(1)
   .get()
}

export function firestoreAmministratoreGetLezioniDiRecuperoTfa(corsi, academicYear) {
  if (academicYear) {
    return db.collection("lezioni_tfa")
      .where("corso.descrizione", "in", corsi)
      .where("academicYear", "==", academicYear)
      .where("isRecoveryLesson", "==", true)
      .get();
  } else {
    return db.collection("lezioni_tfa")
      .where("corso.descrizione", "in", corsi)
      .where("isRecoveryLesson", "==", true)
      .get();
  }
}

export function firestoreGetLezioniDiRecuperoTfa(date, academicYear) {
  if (date) {
    if (academicYear) {
      return db.collection("lezioni_tfa")
        .where("academicYear", "==", academicYear)
        .where("orario.data", "==", date)
        .where("isRecoveryLesson", "==", true)
        .get();
    } else {
      return db.collection("lezioni_tfa")
        .where("orario.data", "==", date)
        .where("isRecoveryLesson", "==", true)
        .get();
    }
  } else {
    if (academicYear) {
      console.log("firestoreGetLezioniTfa academicYear", academicYear)
      return db.collection("lezioni_tfa")
        .where("academicYear", "==", academicYear)
        .where("isRecoveryLesson", "==", true)
        .get();
    } else {
      return db.collection("lezioni_tfa")
        .where("isRecoveryLesson", "==", true)
        .get();
    }
  }
}

export function firestoreTFAGetPresencesByCourseAndStudent(course, student, activeAcademicYear) {
  return db
    .collection("presenze_tfa")
    .where("corso.codice", "==", course)
    .where("studente.email", "==", student)
    .get();
}