import { createStore, applyMiddleware, compose } from 'redux'
import * as Sentry from "@sentry/react";
import thunk from "redux-thunk";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

const middleware = [thunk];

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  loading: false,
  prenotazioni: [],
  presenze: [],
  activeAcademicYear: "",
  oreDiAssenzaStudents: []
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case 'loading':
      return {...state, ...rest }
    case 'setprenotazioni':
      return {...state, loading : false, ...rest }
    case 'setpresenze':
      return {...state, loading : false, ...rest }
    default:
      return state
  }
}

const store = createStore(
  changeState, 
  // sentryReduxEnhancer,
  compose(
    applyMiddleware(...middleware),
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
)
export default store
